import * as React from "react"
import Layout from "../components/layout/layout"
import { graphql } from 'gatsby'
import { useTranslation } from "gatsby-plugin-react-i18next";
import SEOComponent from '../components/seo'
import TitleAltComponent from "../components/title-alt"
import parse from "../utils/html-parser.options"

import "../styles/rte.css"
const LegalNoticePage = ({ data }) => {
  const { t } = useTranslation();
  const siteContent = data.siteContent
  return (
    <main>
      <Layout siteContent={siteContent}>
        <SEOComponent title={t("legal_mention")} />
        <TitleAltComponent title={t("legal_mention")} description={siteContent.staticPage.descriptionLegalNotice} ></TitleAltComponent>
        <div className="container rte px-4 mx-auto">
          {parse(siteContent.staticPage.htmlLegalNotice)}
        </div>
      </Layout>
    </main>
  )
}
export default LegalNoticePage;

export const query = graphql`
query($language: String!) {
  locales: allLocale(filter:{language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
  siteContent(language: {eq: $language}) {
        editionYear
        programYear
        actualites {
          description
        }
        language
        staticPage {
          descriptionLegalNotice
          htmlLegalNotice
        }
        cookieURL
        personalDataURL
        mediaKit
        banner
  }
    newsArticles: allArticle(limit: 40,filter: {typeArticle: {eq: news},language: {eq: $language}}) {
      nodes {
        title
        slug
        shortDescription
        thumbnailImage
      }
      
    }
  }
`